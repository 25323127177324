/*global $, clearLogonDetails, addToHomescreen, bootbox, showAddressForm, showSuccess, showError, fDebug, showImageForm, showRetDateForm, cloneOrder,show3dPreview,JSONstring,LAM_ERROR_LOG, translator*/
/*exported mobileNavClick, showOrderList, doFeedback, showClothStocks, previousPage, fActiveGrid, hideLoading,relinkControls, showTrackingDetails */
/*jshint esversion: 6 */

var cBrand = 'ingot';
var fActiveGrid;

var previousPage;

var pageArray = [{
    title: "Home",
    target: "startpagediv",
    id: "startpage",
    class: "c-startpage",
    url: "/scripts-pas/startpage.fsscript",
    showing: false
}, {
    title: "Home Page Editor",
    target: "edithomediv",
    id: "edithome",
    class: "c-edithome",
    url: "/scripts-pas/edithomepage.fsscript",
    showing: false
}, {
    title: "TOPS II",
    target: "ordersdiv",
    id: "entry2",
    class: "c-entry2",
    url: "/scripts-pas/listactive.fsscript",
    showing: false
}, {
    title: "Create Order",
    target: "neworderdiv",
    id: "neworder",
    class: "c-neworder",
    alternate: "entry2",
    url: "/entry2/createorder.fsscript",
    showing: false
}, {
    title: "Edit Order",
    target: "editordiv",
    id: "editor",
    class: "c-editor",
    alternate: "entry2",
    url: "/entry2/editorder.fsscript?RGUID=",
    showing: false
}, {
    title: "3D Preview",
    target: "preview3ddiv",
    id: "preview3d",
    class: "c-preview3d",
    alternate: "entry2",
    showing: false,
    OnClick: (function (param) {
        showLoadingPage();
        $('#preview3ddiv').load('/entry2/editorder.fsscript?VIEW=true&3D=true&RGUID=' + param, function (_response, status, xhr) {
            if (fDebug) {
                console.log('Editor Page Loaded');
            }
            if (status === 'success') {
                show3dPreview(function () {
                    hideLoadingPage();
                });
            } else {
                showError(xhr.statusText);
                console.log(status);
                console.log(xhr);
            }
        });
    })
}, {
    title: "Print Order",
    target: "printorderdiv",
    id: "printorder",
    class: "c-printorder",
    alternate: "entry2",
    url: "/scripts-pas/printorder.fsscript?RGUID=",
    showing: false
}, {
    title: "Print Order",
    target: "printorderdiv",
    id: "printorderlong",
    class: "c-printorderlong",
    alternate: "entry2",
    url: "/scripts-pas/printorderlong.fsscript?RGUID=",
    showing: false
}, {
    title: "LGFG Search",
    target: "lgfgsearchdiv",
    id: "lgfgsearch",
    class: "c-lgfgsearch",
    url: "/scripts-pas/listlgfgsearch.fsscript",
    showing: false
}, {
    title: "Customers",
    target: "customersdiv",
    id: "customers",
    class: "c-customers",
    url: "/scripts-pas/listcustomers.fsscript",
    showing: false
}, {
    title: "Edit Customer",
    target: "editcustomerdiv",
    id: "editcustomer",
    class: "c-editcustomer",
    alternate: "customers",
    url: "/scripts-pas/editcustomer.fsscript?OGUID=",
    showing: false
}, {
    title: "Merge Customers",
    target: "mergecustomerdiv",
    id: "mergecustomer",
    class: "c-mergecustomer",
    alternate: "customers",
    url: "/scripts-pas/mergecustomers.fsscript?OGUID=",
    showing: false
}, {
    title: "House Bunches",
    target: "housebunchesdiv",
    id: "housebunches",
    class: "c-housebunches",
    url: "",
    showing: false,
    OnClick: (function () {
        $('#housebuncheslist').unbind('change').on('change', function () {
            $('#clothpictures').load('/housebunches/lookupclothsdir.fsscript?bunch=' + encodeURI($('#housebuncheslist').val()));
        });
        $('#housebuncheslist').trigger('change');
        showPage(this, window.location.search, previousPage);
    })
}, {
    title: "TOPS Live",
    target: "topslivediv",
    id: "topslive",
    class: "c-topslive",
    url: "/scripts-pas/livelisttops.fsscript",
    showing: false
}, {
    title: "Tracking",
    target: "trackingdiv",
    id: "tracking",
    class: "c-tracking",
    url: "/scripts-pas/listtracking.fsscript",
    showing: false
}, {
    title: "Order Tracking",
    target: "ordertrackingdiv",
    id: "ordertracking",
    class: "c-ordertracking",
    url: "/scripts-pas/listordertracking.fsscript",
    showing: false
}, {
    title: "Edit Tracking",
    target: "edittrackingdiv",
    id: "edittracking",
    class: "c-edittracking",
    url: "/scripts-pas/editprodstat.fsscript?RECG=",
    showing: false
}, {
    title: "Cloth Boxes",
    target: "clothboxesdiv",
    id: "clothboxes",
    class: "c-clothboxes",
    url: "/scripts-pas/listclothboxes.fsscript",
    showing: false
}, {
    title: "Basket",
    target: "basketdiv",
    id: "basket",
    class: "c-basket",
    url: "/invoices/basket.fsscript?IDRetailer=",
    showing: false
}, {
    title: "Basket Response",
    target: "basketresponsediv",
    id: "basketresponse",
    class: "c-basketresponse",
    url: "/basketresponse.fsscript",
    showing: false
}, {
    title: "Invoicing",
    target: "invoicesdiv",
    id: "invoices",
    class: "c-invoices",
    url: "/scripts-pas/listinvoices.fsscript",
    showing: false
}, {
    title: "Price Lists",
    target: "pricelistsdiv",
    id: "pricelists",
    class: "c-pricelists",
    url: "/scripts-pas/listprices.fsscript",
    showing: false
}, {
    title: "Retailer Zone",
    target: "retailerzonediv",
    id: "retailerzone",
    class: "c-retailerzone",
    url: "/scripts-pas/retailerzone.fsscript",
    showing: false
}, {
    title: "Gallery",
    target: "gallerydiv",
    id: "gallery",
    class: "c-gallery",
    url: "/scripts-pas/gallery.fsscript",
    showing: false
}, {
    title: "Price Lookup",
    target: "pricelookupdiv",
    id: "pricelookup",
    class: "c-pricelookup",
    url: "/scripts-pas/priceslookup.fsscript",
    showing: false
}, {
    title: "TOPS Lite",
    target: "litopslitediv",
    id: "topslite",
    class: "c-topslite",
    url: "https://lite.ingot.cloud",
    showing: false,
    OnClick: (function () {
        console.log('redirecting to https://lite.ingot.cloud');
        window.open("https://lite.ingot.cloud", '_blank');
    })
}, {
    title: "To Do",
    target: "tododiv",
    id: "todo",
    class: "c-todo",
    url: "/scripts-pas/todo.fsscript",
    showing: false
}, {
    title: "Administration",
    target: "admindiv",
    id: "admin",
    class: "c-admin",
    url: "/admin.fsscript",
    showing: false
}, {
    title: "Resources",
    target: "resourcesdiv",
    id: "resources",
    class: "c-resources",
    url: "/scripts-pas/resources.fsscript",
    showing: false
}, {
    title: "Factory Cloth Availability",
    target: "clothstocksdiv",
    id: "clothstocks",
    class: "c-clothstocks",
    url: "/scripts-pas/listclothstocks.fsscript",
    showing: false
}, {
    title: "Cloth Out of Stock",
    target: "clothoutofstocksdiv",
    id: "clothoutofstocks",
    class: "c-clothoutofstocks",
    url: "/scripts-pas/clothstocks.fsscript",
    showing: false
}, {
    title: "Employees",
    target: "employeesdiv",
    id: "employees",
    class: "c-employees",
    url: "/scripts-pas/listemployees.fsscript",
    showing: false
}, {
    title: "Edit Employees",
    target: "editemployeesdiv",
    id: "editemployees",
    class: "c-editemployees",
    url: "/scripts-pas/editemployees.fsscript?RECG=",
    showing: false
}, {
    title: "Settings",
    target: "settingsdiv",
    id: "settings",
    class: "c-settings",
    url: "/settings.fsscript",
    showing: false
}, {
    title: "Logout",
    target: "logout",
    id: "",
    class: "logout_button",
    url: "",
    showing: false,
    OnClick: (function () {
        $('#mainbody').html('<h3 class="loading"><i class="fa fa-cog fa-spin"></i> Logging Out...</h3>');
        $('#heading').html('');
        $.ajax({
            type: 'post',
            async: true,
            url: '/login.fsscript?logout=true', // in here you should put your query
            data: {
                login: false,
                logout: true
            },
            success: function (r) {
                clearLogonDetails(r);
                window.location = '/';
            }
        });
    })
}, {
    title: "Bookmark",
    target: "bookmark",
    url: "",
    id: "bookmark",
    class: "c-bookmark",
    showing: false,
    OnClick: (function () {
        if (window.sidebar && window.sidebar.addPanel) { // Mozilla Firefox Bookmark
            window.sidebar.addPanel(document.title, window.location.href, '');
        } else if (window.external && ('AddFavorite' in window.external)) { // IE Favorite
            window.external.AddFavorite(location.href, document.title);
        } else if (window.opera && window.print) { // Opera Hotlist
            this.title = document.title;
            return true;
        } else if (navigator.userAgent.toLowerCase().indexOf('iphone') != -1) {
            var addtohomeiPhone = new addToHomescreen({
                appID: 'cloud.' + cBrand + '.topsweb',
                startDelay: 0,
                autostart: false
            });
            addtohomeiPhone.show(true);
        } else if (navigator.userAgent.toLowerCase().indexOf('ipad') != -1) {
            var addtohomeiPad = new addToHomescreen({
                appID: 'cloud.' + cBrand + '.topsweb',
                startDelay: 0,
                autostart: false
            });
            addtohomeiPad.show(true);
        } else if (navigator.userAgent.toLowerCase().indexOf('windows phone') != -1) {
            alert('Press "..." button and select pin to start.');
        } else { // webkit - safari/chrome
            alert('Press ' + (navigator.userAgent.toLowerCase().indexOf('mac') != -1 ? 'Command/Cmd' : 'CTRL') + ' + D to bookmark this page.');
        }
        $('#bookmark').removeClass('active');
    })
}];

// Change the Button Toggle State

function isSubDivVisible(pID) {
    var divName = '#' + pID;
    if ($(divName).hasClass('hidden') === true || $(divName).hasClass('show') === true) {
        if ($(divName).hasClass('hidden') === true && $(divName).hasClass('show') === false) {
            return false;
        } else {
            return true;
        }
    } else {
        if ($(divName).css('display') !== 'none') { // display: none;
            return true;
        } else {
            return false;
        }
    }
}

function showPage(pObj, pQuery, prev) {
    showLoadingPage();
    previousPage = prev;
    if (pObj !== undefined && typeof ($(pObj).dropdown()) === 'function') {
        $(pObj).dropdown('toggle');
    }
    // console.log(pObj);
    // console.log(previousPage);
    pageArray.forEach(function (item) {
        if (item.id) {
            $('#li' + item.id).removeClass('active');
            $('#' + item.id).removeClass('active');
            $('#' + item.target).removeClass('active');
            $('#' + item.alternate).removeClass('active');
            $('.' + item.class).removeClass('active');
        }
    });

    if (fDebug) {
        console.log('============== ShowPage ==============');
        console.log(pObj);
    }

    localStorage.setItem('cloud.' + cBrand + '.startURL', pObj.url);
    localStorage.setItem('cloud.' + cBrand + '.startTitle', pObj.title);
    localStorage.setItem('cloud.' + cBrand + '.startPage', pObj.target);
    localStorage.setItem('cloud.' + cBrand + '.startid', pObj.id);
    localStorage.setItem('cloud.' + cBrand + '.query', pQuery);

    function doShow(r) {
        var that = $('#' + pObj.target);
        if (r !== '') {
            that.html(r);
        }
        $('#navtitle').html(pObj.title);
        $('#li' + pObj.id).addClass('active');
        $('#' + pObj.id).addClass('active');
        $('#' + pObj.alternate).addClass('active');
        $('.' + pObj.class).addClass('active');
        var found = false;
        pageArray.forEach(function (item) {
            if ($('#' + item.target).hasClass('show')) {
                found = true;
                if (fDebug) {
                    console.log($('#' + item.target));
                    console.log(that);
                }
                $('#' + item.target).addClass('hidden').removeClass('show');
                that.addClass('show').removeClass('hidden');
            }
        });
        if (!found) {
            console.error('not found');
            console.log(pObj);
            that.addClass('show').removeClass('hidden');
        }

        hideLoadingPage();
    }

    if (pObj.url !== '') {
        try {
            $.ajax({
                type: 'post',
                async: true,
                url: pObj.url + pQuery,
                success: function (r) {
                    doShow(r);
                    $('.dropdown-toggle').dropdown();
                },
                error: function (request, status, error) {
                    $('#' + pObj.target).html('<h1>' + error + '</h1>');

                    console.log(request);
                    console.log(status);
                    console.log(error);

                    hideLoadingPage();

                    if (request.status === 401) {
                        $.ajax({
                            type: 'post',
                            async: true,
                            url: '/login.fsscript?logout=true', // in here you should put your query
                            data: {
                                login: false,
                                logout: true
                            },
                            success: function (r) {
                                clearLogonDetails(r);
                                window.location = '/';
                                $('.dropdown-toggle').dropdown();
                            }
                        });
                    }
                }
            });
        } catch (e) {
            $('#' + pObj.target).html('<h1>Error</h1>' + e);
            hideLoadingPage();
            $('.dropdown-toggle').dropdown();
        }
    } else {
        doShow('');
        $('.dropdown-toggle').dropdown();
    }
}

// function revertPage(pPage, target) {
//     if (target !== null && target !== '') {
//         var curPage = $('#li' + target);
//         if (curPage !== undefined) {
//             if (curPage.hasClass('active')) {
//                 curPage.removeClass('active');
//             }
//         }
//         curPage = $('#li' + pPage);
//         if (curPage !== undefined) {
//             if (!curPage.hasClass('active')) {
//                 curPage.addClass('active');
//             }
//         }
//     }
// }

function changePage(id, param, prev) {
    //console.log(id);
    var lObj = pageArray.find(function (pObj) {
        return pObj.id === id;
    });
    if (typeof lObj === 'object') {
        if (fDebug) {
            console.log(lObj);
        }
        if (typeof lObj.OnClick === 'function') {
            if (fDebug) {
                console.log('running function');
            }
            lObj.OnClick(param);
        } else {
            if (fDebug) {
                console.log('showing page:' + lObj.target);
                //console.log(typeof lObj.OnClick);
            }
            if (param) {
                showPage(lObj, param, prev);
            } else {
                showPage(lObj, window.location.search, prev);
            }
        }
    } else {
        console.error('Object Not Found:' + id);
        console.error(lObj);
        showPage(pageArray[0], '');
    }
}

function checkPageChange(id, param, prev) {
    var lStartPage = localStorage.getItem('cloud.' + cBrand + '.startPage');
    if (lStartPage === 'editordiv' && localStorage.getItem('cloud.' + cBrand + '.TOPSII') !== false) {
        if (id !== 'editor') {
            if (isSubDivVisible('editordiv')) {
                if ($('#CHK_AUTOSAVE').prop('checked') !== true) {
                    bootbox.confirm('<h4><i class="fa fa-exclamation-triangle fa-2x text-warning"></i> This will loose all entered order details, continue?</h4>', function (result) {
                        if (result === true) {
                            changePage(id, param, prev);
                            // } else {
                            //     revertPage('entry2', id, param);
                        }
                    });
                } else {
                    changePage(id, param, prev);
                }
            } else {
                changePage(id, param, prev);
            }
        } else {
            changePage(id, param, prev);
        }
    } else if (lStartPage === 'editcustomerdiv') {
        if (id !== 'editcustomer') {
            if (isSubDivVisible('editcustomerdiv')) {
                bootbox.confirm('<h4><i class="fa fa-exclamation-triangle fa-2x text-warning"></i> 	Abandon Customer Changes?</h4>', function (result) {
                    if (result === true) {
                        changePage(id, param, prev);
                        // } else {
                        //     revertPage('editcustomer', id, param);
                    }
                });
            } else {
                changePage(id, param, prev);
            }
        } else {
            changePage(id, param, prev);
        }
    } else {
        changePage(id, param, prev);
    }
}

// Then actually do the click
function mobileNavClick(e) {
    if (fDebug) {
        console.log('mobilenav click');
    }
    try {
        if (typeof $('#lts-navbar-collapse').collapse === 'function') {
            $('#lts-navbar-collapse').collapse('hide');
        }
    } catch (err) {
        console.log(err);
    }
    var id = $(this).attr('id');
    if (!$(this).hasClass('active')) {
        $(this).addClass('active');
    }
    e.preventDefault();
    checkPageChange(id, '', previousPage);
}

/* $('.buttonnav').unbind('click').on('click', function(e) {
    var id = $(this).attr('id');
    if (fDebug) {
        console.debug('Pressed:' + id);
    }
    e.preventDefault();
    checkPageChange(id);
});*/

var showLoadingPage = function () {
    if (typeof $.LoadingOverlay === 'function') {
        try {
            $.LoadingOverlay("show", {
                image: "",
                size: false,
                fontawesomeAutoResize: false,
                fontawesome: "fa fa-cog fa-spin fa-3x"
            });
        } catch (error) {
            $('.sm-loading').show();
            console.error('Loading Overlay Not Found');
            console.error(typeof $.LoadingOverlay);
            console.error(error);
        }
    } else {
        $('.sm-loading').show();
        console.error('Loading Overlay Not Found');
    }
};

var relinkControls = function () {
    try {
        $('.nav-tabs a').unbind('click').on('click', function (e) {
            e.preventDefault();
            $(this).tab('show');
        });

        $('.navbar li').on('click', function (e) {
            $('.navbar li.active').removeClass('active');
            var navbut = $(this);
            if (!navbut.hasClass('active')) {
                navbut.addClass('active');
            }
            e.preventDefault();
        });

        $('.collapsed').unbind('click').on('click', function (e) {
            e.preventDefault();
            var id = $(this).attr('href');
            $(id).collapse('toggle');
        });

        $('.panel-title a').unbind('click').on('click', function (e) {
            e.preventDefault();
            var id = $(this).attr('href');
            $(id).collapse('toggle');
        });

        $(document).on('click.bs.toggle', 'div[data-toggle^=toggle]', function (e) {
            e.preventDefault();
            var $checkbox = $(this).find('input[type=checkbox]');
            $checkbox.bootstrapToggle('toggle');
        });
        if (fDebug) {
            console.debug('Relinked');
        }
    } catch (error) {
        console.error(error);
    }
};

var hideLoadingPage = function () {
    if (typeof $.LoadingOverlay === 'function') {
        try {
            $.LoadingOverlay("hide");
        } catch (error) {
            $('.loadingoverlay').addClass('hidden');
            console.error('Loading Overlay Not Found');
            console.error(typeof $.LoadingOverlay);
            console.error(error);
        }
    } else {
        console.error('Loading Overlay Not Found');
        $('.loadingoverlay').addClass('hidden');
    }
    $('.sm-loading').hide();
};

var hideLoading = function () {
    if (typeof $.LoadingOverlay === 'function') {
        try {
            $.LoadingOverlay("hide");
        } catch (error) {
            $('.loadingoverlay').addClass('hidden');
            console.error('Loading Overlay Not Found');
            console.error(typeof $.LoadingOverlay);
            console.error(error);
        }
    } else {
        console.error('Loading Overlay Not Found');
        $('.loadingoverlay').addClass('hidden');
    }
    $('.sm-loading').hide();
};

function levelToStr(pLevel) {

    switch (parseInt(pLevel)) {
        case 500:
            return 'Live Orders (Not Inc Drafts)';
        case 600:
            return 'Live Orders (NOT Drafts or 4040)';
        case 800:
            return 'Web Order Uploads';
        case 825:
            return 'Verify Web Order';
        case 840:
            return 'Web Orders With Errors';
        case 850:
            return 'Web Orders for Review';
        case 900:
            return 'Held Orders';
        case 990:
            return 'Draft Orders (online)';
        case 995:
            return 'Waiting Approval';
        case 1000:
            return 'Draft Orders (admin)';
        case 1001:
            return 'Order Entry Overrides';
        case 1002:
            return 'Etch Import Errors';
        case 1005:
            return 'LGFG Specials';
        case 1007:
            return 'Orders with Pattern Queries';
        case 1008:
            return 'Orders with Comments Cloth Issues';
        case 1009:
            return 'Orders with Comments Waiting Info';
        case 1010:
            return 'Orders With Comments';
        case 1015:
            return 'Orders with Wholesaler Failures';
        case 1020:
            return 'Orders with Verify Failures';
        case 2000:
            return 'Auto Cloth Check';
        case 2010:
            return 'Remake Requests';
        case 2020:
            return 'Cloth Errors Awaiting Info';
        case 2022:
            return 'Cloth Errors Waiting for Cloth';
        case 2026:
            return 'Cloth Errors';
        case 2028:
            return 'Check for Comments';
        case 2029:
            return 'Manual Comment Check';
        case 2030:
            return 'Generate Proforma Invoice';
        case 2031:
            return 'CS Order Check';
        case 2032:
            return 'Etch Import Check';
        case 2033:
            return 'Check Required Date';
        case 2034:
            return 'Inkspin Check';
        case 2035:
            return 'Auto Calculate Order Totals';
        case 2036:
            return 'Lite Orders Waiting Approval';
        case 2037:
            return 'Waiting Approval';
        case 2039:
            return 'Finance Errors';
        case 2040:
        case 2042:
            return 'Pre Production Payment Required';
        case 2044:
            return 'Auto Allocate Factory';
        case 2045:
            return 'Factory Allocation';
        case 2046:
            return 'Factory Allocation';
        case 2047:
            return 'Factory Allocation';
        case 2048:
            return 'Factory Allocation';
        case 2049:
            return 'Factory Allocation';
        case 2050:
            return 'Factory Allocation';
        case 2051:
            return 'Factory Allocation';
        case 2052:
            return 'Factory Allocation';
        case 2053:
            return 'Factory Allocation';
        case 2054:
            return 'Factory Allocation';
        case 2055:
            return 'Factory Allocation';
        case 2056:
            return 'Factory Allocation';
        case 2500:
            return 'Auto Generate Order PDFs';
        case 2600:
            return 'LGFG Orders for Review';
        case 2800:
            return 'Reuse Previous Stock Order Data';
        case 2999:
            return 'Pieces Test';
        case 3000:
        case 3001:
        case 3002:
        case 3003:
        case 3004:
        case 3005:
            return 'Auto Piece Selection';
        case 3009:
            return 'Inkspin MI';
        case 3010:
            return 'Piece Selection Failures';
        case 3011:
        case 3012:
        case 3013:
        case 3014:
        case 3015:
        case 3016:
            return 'Manual Intervention';
        case 3017:
            return 'Auto Marker Check';
        case 3018:
            return 'Auto Upload LayS';
        case 3019:
            return 'Orders with Missing LayS Files';
        case 3020:
            return 'Auto Gather Markers';
        case 3025:
            return 'Orders With Missing Markers';
        case 3030:
            return 'Auto Read Cloth Lengths';
        case 3035:
            return 'Error Reading Cloth Lengths';
        case 3050:
            return 'Auto Add Order to Cloth Ordering Batch';
        case 3060:
            return 'Auto Check for Plot/Cut Files';
        case 3061:
            return 'Missing Plot / Cut Files';
        case 3062:
            return 'Cloth Stock Errors';
        case 3071:
            return 'Create Factory XML Files';
        case 3075:
            return 'Pending Stock Orders';
        case 3076:
            return 'MTM XLS Edit';
        case 3080:
            return 'Create Factory Zip Files';
        case 3082:
            return 'Error Creating Factory Zip File';
        case 3085:
        case 3090:
            return 'Cloth Ready to Order';
        case 3095:
        case 3105:
            return 'Auto EMail Cloth Orders';
        case 3150:
            return 'Cloth Discontinued';
        case 3175:
            return 'Cloth Delayed';
        case 3180:
            return 'Cloth Delayed Customer Supplied';
        case 3190:
            return 'Customer Supplied';
        case 3200:
            return 'Cloth Ordered and Waiting';
        case 3500:
            return 'Send Cloth to Factory';
        case 3550:
            return 'Cloths In Box';
        case 3600:
            return 'Cloth Boxed & Waiting Collection';
        case 3700:
            return 'Waiting For Cloth to Arrive at Factory';
        case 3705:
            return 'Waiting for Factory Cloths to Arrive';
        case 3725:
            return 'Cloth Delayed in Shipping to Factory';
        case 3750:
            return 'Check for Factory Comments';
        case 3751:
            return 'Order Failed Comment Check';
        case 3752:
            return 'Send Order to Factory';
        case 3753:
            return 'Order Allocated to Wrong Factory';
        case 3755:
            return 'Error Sending Order to Factory';
        case 3800:
            return 'Refresh Proforma Invoice';
        case 3850:
            return 'Error Refreshing Proforma Invoice';
        case 3900:
            return 'Waiting for Cloth To Arrive at Factory';
        case 4000:
            return 'Factory Production Check';
        case 4001:
            return 'Factory Production Query (Shirts)';
        case 4002:
            return 'Factory Production Query';
        case 4003:
            return 'Cloth Overdue at Factory';
        case 4004:
            return 'LGFG Ready To Ship';
        case 4005:
            return 'In Production';
        case 4006:
            return 'In Production Shirts';
        case 4007:
            return 'Garments In Transit';
        case 4008:
            return 'Retailer On Stop';
        case 4009:
            return 'Waiting Payment';
        case 4010:
            return 'Waiting Approval';
        case 4011:
            return 'Finishing';
        case 4012:
            return 'Factory Comment Check';
        case 4015:
            return 'Garments Ready to Dispatch';
        case 4020:
            return 'Garment Needs Alteration';
        case 4030:
            return 'Garment Boxed for Delivery';
        case 4040:
            return 'Shipped to LGFG location';
        case 4050:
            return 'Dispatched To Customer';
        case 4052:
            return 'With Customer for Try-On';
        case 4053:
            return 'Auto Clone Try On Jacket';
        case 4054:
            return 'Try-On Garment at Factory';
        case 4055:
            return 'Auto Create Invoice Components';
        case 4056:
            return 'Error Creating Invoice';
        case 4060:
            return 'Invoice Customer';
        case 4065:
            return 'Pending Invoices';
        case 4067:
            return 'Invoices Waiting to be Emailed';
        case 4070:
            return 'Invoice Waiting Payment';
        case 4500:
            return 'Archive Completed Order Data';
        case 4501:
            return 'Error Archiving Completed Order Data';
        case 4550:
            return 'BO Not Sent';
        case 4551:
            return 'BO Retailer On Stop';
        case 4552:
            return 'BO Waiting Payment';
        case 4800:
            return 'Garments Available to Ship';
        case 4900:
            return 'Active Orders';
        case 4950:
            return 'Orders Assigned To Me';
        case 5000:
        case 5006:
        case 5007:
        case 5008:
        case 5009:
        case 5010:
        case 5011:
        case 5012:
        case 5013:
        case 5014:
        case 5015:
        case 5016:
        case 5017:
        case 5018:
        case 5019:
        case 5020:
        case 5021:
        case 5022:
        case 5023:
        case 5024:
        case 5025:
        case 5026:
        case 5027:
        case 5028:
        case 5029:
        case 5030:
        case 5031:
        case 5032:
        case 5033:
        case 5034:
        case 5035:
        case 5036:
        case 5037:
        case 5038:
        case 5039:
        case 5040:
        case 5041:
        case 5042:
        case 5043:
        case 5044:
        case 5045:
        case 5046:
        case 5047:
        case 5048:
        case 5049:
        case 5050:
            return 'Completed Orders';
        case 9999:
            return 'Label Printout';
        case 10000:
            return 'Cancelled Orders';
        default:
            return 'Unknown ' + pLevel;
    }
}

function levelToStrLGFG(pLevel) {

    switch (parseInt(pLevel)) {
        case 500:
            return 'Live Orders (Not Inc Drafts)';
        case 800:
        case 825:
        case 840:
        case 850:
        case 900:
        case 990:
        case 995:
            return 'Draft Orders';
        case 1000:
            return 'Lambton Admin Drafts';
        case 1001:
        case 1002:
        case 1007:
        case 1008:
        case 1009:
        case 1010:
        case 1015:
        case 1020:
        case 2000:
        case 2010:
        case 2020:
        case 2022:
        case 2026:
        case 2028:
        case 2029:
        case 2030:
        case 2032:
        case 2035:
        case 2037:
        case 2039:
        case 2040:
        case 2042:
        case 2044:
        case 2045:
        case 2046:
        case 2047:
        case 2048:
        case 2049:
        case 2050:
        case 2051:
        case 2052:
        case 2053:
        case 2054:
        case 2055:
        case 2056:
        case 2500:
        case 2800:
        case 2999:
        case 3000:
        case 3001:
        case 3002:
        case 3003:
        case 3004:
        case 3005:
        case 3009:
        case 3010:
        case 3011:
        case 3012:
        case 3013:
        case 3014:
        case 3015:
        case 3016:
        case 3017:
        case 3018:
        case 3019:
        case 3020:
        case 3025:
        case 3030:
        case 3035:
        case 3050:
        case 3060:
        case 3061:
        case 3062:
        case 3071:
        case 3073:
        case 2600:
        case 3075:
        case 3076:
        case 3080:
        case 3082:
        case 3085:
        case 3090:
        case 3095:
        case 3105:
        case 3150:
        case 3175:
        case 3190:
        case 3200:
        case 3500:
        case 3550:
        case 3600:
        case 3700:
        case 3725:
        case 3750:
        case 3751:
        case 3752:
        case 3753:
        case 3755:
        case 3800:
        case 3850:
        case 3900:
        case 4000:
        case 4001:
        case 4002:
        case 4003:
        case 4005:
        case 4006:
            return 'In Production';
        case 4004:
            return 'LGFG Ready To Ship';
        case 4040:
            return 'Shipped to LGFG location';
        case 4007:
        case 4008:
        case 4009:
        case 4010:
        case 4011:
        case 4015:
        case 4020:
        case 4030:
        case 4050:
        case 4052:
        case 4053:
        case 4054:
        case 4055:
        case 4056:
        case 4060:
        case 4065:
        case 4070:
        case 4500:
        case 4501:
        case 4900:
            return 'Shipped';
        case 4950:
            return 'Orders Assigned To Me';
        case 5000:
        case 5006:
        case 5007:
        case 5008:
        case 5009:
        case 5010:
        case 5011:
        case 5012:
        case 5013:
        case 5014:
        case 5015:
        case 5016:
        case 5017:
        case 5018:
        case 5019:
        case 5020:
        case 5021:
        case 5022:
        case 5023:
        case 5024:
        case 5025:
        case 5026:
        case 5027:
        case 5028:
        case 5029:
        case 5030:
        case 5031:
        case 5032:
        case 5033:
        case 5034:
        case 5035:
        case 5036:
        case 5037:
        case 5038:
        case 5039:
        case 5040:
        case 5041:
        case 5042:
        case 5043:
        case 5044:
        case 5045:
        case 5046:
        case 5047:
        case 5048:
        case 5049:
        case 5050:
        case 9999:
            return 'Completed Orders';
        case 10000:
            return 'Cancelled Orders';
        default:
            return 'Unknown ' + pLevel;
    }
}

var showOrderList = function (btnNew, pApproved, pEditing, pClone, p3D, pAddress) {

    var lApproved;
    if (!pApproved) {
        lApproved = '<option value=\"4\">Waiting Approval</option>';
    }
    var barWidth = 50;

    fActiveGrid = $("#grid-orderlist");

    var grid = $("#grid-orderlist").bootgrid({
        ajax: true,
        post: function () {
            /* To accumulate custom parameter with the request object */
            return {
                id: "b0df282a-0d67-40e5-8558-c9e93b7befed",
                type: localStorage.getItem('cloud.' + cBrand + '.orderfiltertype')
            };
        },
        requestHandler: function (request) {
            if (fDebug) {
                console.log(request);
            }
            return request;
        },
        keepSelection: true,
        url: "/entry2/lookup.fsscript",
        rowCount: [50, 100, 200, -1],
        selection: false,
        multiSelect: false,
        columnSelection: true,
        statusMapping: {
            0: "ok",
            1: "prior1",
            2: "prior2",
            3: "prior3"
        },
        searchSettings: {
            delay: 250,
            characters: 1
        },
        labels: {
            loading: "<i class='fa fa-cog fa-spin'></i> Loading...",
            all: "All",
            infos: "Showing {{ctx.start}} to {{ctx.end}} of {{ctx.total}} entries",
            noResults: "No results found!",
            refresh: "Refresh",
            search: "Search"
        },
        templates: {
            header: "<div id=\"{{ctx.id}}\" class=\"{{css.header}}\"><div class=\"row\"><div class=\"col-xs-12 col-sm-12 col-md-12 col-lg-12 actionBar\">" + btnNew + "<p class=\"{{css.search}}\"></p><div class=\"orderfilter form-group\"><div class=\"input-group\"><select class=\"form-control orderfilter\" id=\"orderfiltertype\" name=\"orderfiltertype\"><option value=\"0\">Draft Orders</option>" + lApproved + "<option value=\"1\">Active Orders</option><option value=\"2\">Completed Orders</option><option value=\"3\">All Orders</option></select></div></div><p class=\"{{css.actions}}\"></p></div></div></div>"
        },
        converters: {
            datetime: {
                from: function (value) {
                    return new Date(value);
                },
                to: function (value) {
                    var dt = Date.parse(value);
                    var dto = new Date(dt);
                    if (dt < 0) {
                        return ('TBA');
                    } else {
                        return (dto.toLocaleDateString());
                    }
                }
            }
        },
        formatters: {
            "linkORDERID": function (column, row) {
                return "<a href=\"javascript:void(0)\" class=\"editorder\" data-rguid=" + row.IGUID + "\">" + row.ORDER_ID + "</a>";
            },
            "linkRETORDID": function (column, row) {
                return "<a href=\"/entry2/editorder.fsscript?RGUID=" + row.IGUID + "\">" + row.RETORDID + "</a>";
            },
            "linkDTCRE": function (column, row) {
                return "<a href=\"/entry2/editorder.fsscript?RGUID=" + row.IGUID + "\">" + row.DTCRE + "</a>";
            },
            "linkDTLAS": function (column, row) {
                return "<a href=\"/entry2/editorder.fsscript?RGUID=" + row.IGUID + "\">" + row.DTLAS + "</a>";
            },
            "linkLEVEL": function (column, row) {
                if (parseInt(localStorage.getItem('cloud.' + cBrand + '.IDWholesaler')) === 4) {
                    return levelToStrLGFG(row.Level);
                } else {
                    return levelToStr(row.Level);
                }
            },
            "linkPRODS": function (column, row) {
                return "<a href=\"/entry2/editorder.fsscript?RGUID=" + row.IGUID + "\">" + row.PRODS + "</a>";
            },
            "orderidnowrap": function (column, row) {
                return '<div class="text-nowrap">' + row.ORDER_ID + '</div>';
            },
            "ffcheckbox": function (column, row) {
                if (row.FF) {
                    return "<i class='fa fa-check-square-o'></i>";
                } else {
                    return "<i class='fa fa-square-o'></i>";
                }
            },
            "feedback": function (column, row) {
                function getRating(r) {
                    if (r === 1) {
                        return '<i class="fas fa-star"></i>' +
                            '<i class="fal fa-star"></i>' +
                            '<i class="fal fa-star"></i>';
                    } else if (r === 2) {
                        return '<i class="fas fa-star"></i>' +
                            '<i class="fas fa-star"></i>' +
                            '<i class="fal fa-star"></i>';
                    } else if (r === 3) {
                        return '<i class="fas fa-star"></i>' +
                            '<i class="fas fa-star"></i>' +
                            '<i class="fas fa-star"></i>';
                    } else {
                        return '<i class="fal fa-star"></i>' +
                            '<i class="fal fa-star"></i>' +
                            '<i class="fal fa-star"></i>';
                    }
                }
                var lFilter = parseInt(localStorage.getItem('cloud.' + cBrand + '.orderfiltertype'));
                //console.log(row);
                if (lFilter === 2) {
                    return '<a href="#" onclick="return doFeedback(\'' + row.IGUID + '\');">' + getRating(row.FEEDBACK) + '</a>';
                } else {
                    return getRating(row.FEEDBACK);
                }

            },
            "source": function (column, row) {
                if (row.Source !== undefined) {
                    if (row.Source.indexOf('Web Lite') < 0) {
                        return 'Bespoke';
                    } else {
                        return 'Lite';
                    }
                    // if (row.Source.indexOf('Web Lite') < 0) {
                    //     return '<img src = "/img/logos/Logo-B.png" width="40px"></img>';
                    // } else {
                    //     return '<img src = "/img/logos/Logo-L.png" width="40px"></img>';
                    // }
                }
            },
            "getcollectionimg": function (column, row) {
                function collname(pCol) {
                    switch (pCol) {
                        case 0:
                            return "Legacy";
                        case 1:
                            return "Business & Casual";
                        case 2:
                            return "Country Wear";
                        case 3:
                            return "Tuxedo";
                        case 4:
                            return "Formal Wear";
                        case 5:
                            return "Highland Wear";
                        case 6:
                            return "Ladies Wear";
                        case 7:
                            return "Outer Wear";
                        case 8:
                            return "Smoking Jacket";
                        case 9:
                            return "Shirts";
                        case 10:
                            return "Accessories";
                        case 11:
                            return "Kids Business and Casual";
                        default:
                            return "Unknown";
                    }
                }
                return "<img src='/img/icons/" + row.IDCollection + ".png' alt='" + collname(row.IDCollection) + "'>";
            },
            "commands": function (column, row) {
                var lButtons = '';
                var lFilter = parseInt(localStorage.getItem('cloud.' + cBrand + '.orderfiltertype'));

                barWidth = 0;
                var disabledStr = '';
                // console.log('Source:' + row.Source);
                // console.log('Lite:' + row.Source.indexOf('Web Lite')>=0);
                if (row.Source !== undefined) {
                    if (pApproved) {
                        if (parseInt(row.IDCollection) === 0 ||
                            parseInt(row.Level) > 1000 ||
                            parseInt(row.Level) === 995 ||
                            row.Source.indexOf('Web Lite') >= 0) {
                            disabledStr = 'disabled';
                        }
                    } else {
                        if (parseInt(row.IDCollection) === 0 ||
                            parseInt(row.Level) > 1000 ||
                            row.Source.indexOf('Web Lite') >= 0) {
                            disabledStr = 'disabled';
                        }
                    }
                    var disabledStrLite = '';
                    if (row.Source.indexOf('Web Lite') >= 0 || row.IDCollection === 0) {
                        disabledStrLite = 'disabled';
                    }
                    var disabledDelStr = '';
                    if (parseInt(row.Level) > 1000) {
                        disabledDelStr = 'disabled';
                    }
                    var disabled3D = '';
                    if ((parseInt(row.Level) < 1001) || (parseInt(row.IDCollection) === 0)) {
                        disabled3D = 'disabled';
                    }
                    var disabledAddress = '';
                    if ((parseInt(row.Level) >= 4011)) {
                        disabledAddress = 'disabled';
                    }

                    var btnEmail = '<button type=\"button\" class=\"btn btn-default command-email\" data-row-id=\"" + row.IGUID + "\" title=\"Send Email\" data-url=\"mailto:' + row.ACCTMGR.replace(/^mailto:/, "") + '?subject=' + row.ORDER_ID + '/' + row.RETORDID + '\"><span class=\"fas fa-lg fa-mail-bulk\"></span></button>';

                    if (pEditing) {
                        if (lFilter === 0 || lFilter === 3 || lFilter === 4) {
                            var btnEdit = "<button type=\"button\" class=\"btn btn-default command-edit\" data-row-id=\"" + row.IGUID + "\"" + disabledStr + " title=\"Edit Order\"><span class=\"fas fa-lg fa-edit\"></span></button>";
                            lButtons += btnEdit;
                            barWidth += 6;

                            // var btnMTO = "<button type=\"button\" class=\"btn btn-default command-mto\" data-row-id=\"" + row.IGUID + "\"" + disabledStr + "><span class=\"far fa-lg fa-edit\"></span></button>";
                            // lButtons += btnMTO;
                        }
                    }
                    var btnPrint = "<button type=\"button\" class=\"btn btn-default command-print\" data-row-id=\"" + row.IGUID + "\" title=\"Print Order\"><span class=\"fas fa-lg fa-print\"></span></button>";
                    lButtons += btnPrint;
                    barWidth += 6;

                    if (lFilter === 0 || lFilter === 3 || lFilter === 4) {
                        var btnDelete = "<button type=\"button\" class=\"btn btn-default command-delete\" data-row-id=\"" + row.IGUID + "\"" + disabledDelStr + " data-level=" + row.Level + "  title=\"Delete Order\"><span class=\"fas fa-lg fa-trash-alt\"></span></button>";
                        lButtons += btnDelete;
                        barWidth += 6;
                    }
                    if (pClone) {
                        var btnClone = "<button type=\"button\" class=\"btn btn-default command-clone\" data-row-id=\"" + row.IGUID + "\" data-collection=\"" + row.IDCollection + "\" data-idretailer=\"" + row.IDRetailer + "\" data-disabled=\"" + disabledStrLite + "\" " + disabledStrLite + " title=\"Clone Order\"><span class=\"fas fa-lg fa-clone\"></span></button>";
                        lButtons += btnClone;
                        barWidth += 6;
                    }
                    if (p3D) {
                        if (lFilter > 0) {
                            var btn3D = "<button type=\"button\" class=\"btn btn-default command-3d\" data-row-id=\"" + row.IGUID + "\" data-collection=\"" + row.IDCollection + "\" data-idretailer=\"" + row.IDRetailer + "\"" + disabled3D + " title=\"3D View\"><span class=\"fas fa-lg fa-cube\"></span></button>";
                            lButtons += btn3D;
                            barWidth += 6;
                        }
                    }
                    if (lFilter === 0 || lFilter === 3 || lFilter === 3) {
                        var btnImage = "<button type=\"button\" class=\"btn btn-default command-image\" data-row-id=\"" + row.IGUID + "\" title=\"Order Images\"><span class=\"fas fa-lg fa-image\"></span></button>";
                        lButtons += btnImage;
                        barWidth += 6;
                    }
                    if (lFilter !== 2) {
                        var btnRetDate = "<button type=\"button\" class=\"btn btn-default command-retdate\" data-row-id=\"" + row.IGUID + "\" title=\"Retailer Date\"><span class=\"fas fa-lg fa-calendar-check\"></span></button>";
                        lButtons += btnRetDate;
                        barWidth += 6;
                    }
                    if (pAddress) {
                        if (lFilter !== 2) {
                            var btnAddress = "<button type=\"button\" class=\"btn btn-default command-address\" data-row-id=\"" + row.IGUID + "\" " + disabledAddress + " title=\"Delivery Address\"><span class=\"fas fa-lg fa-address-card\"></span></button>";
                            lButtons += btnAddress;
                            barWidth += 6;
                        }
                    }
                    lButtons += btnEmail;
                    $(".editbutton").css('width', barWidth + '%');
                }
                return '<div class=\"btn-group\" role=\"group\">' + lButtons + '</div>';
            }
        }
    }).on("load.rs.jquery.bootgrid", function () {
        //$('.search-field').val(localStorage.getItem('cloud.' + cBrand + '.orderfilter'));
        //$("#grid-orderlist").bootgrid('search', localStorage.getItem('cloud.' + cBrand + '.orderfilter'));
    }).on("selected.rs.jquery.bootgrid", function (e, rows) {
        var rowIds = [];
        for (var i = 0; i < rows.length; i++) {
            rowIds.push(rows[i].id);
        }
        alert("Select: " + rowIds.join(","));
    }).on("deselected.rs.jquery.bootgrid", function (e, rows) {
        var rowIds = [];
        for (var i = 0; i < rows.length; i++) {
            rowIds.push(rows[i].id);
        }
        alert("Deselect: " + rowIds.join(","));
    }).on("loaded.rs.jquery.bootgrid", function () {
        try {
            $.ajax({
                type: 'post',
                async: false,
                dataType: 'json',
                url: '/scripts-pas/checklogin.fsscript',
                error: function (e) {
                    if (e.status === 401) {
                        $(location).attr('href', '/');
                    }
                }
            });
        } catch (e) {
            console.log(e);
        }

        localStorage.setItem('cloud.' + cBrand + '.orderfilter', $('.search-field').val());

        var myTimer;
        $('.search-field').on('keydown keyup change copy paste cut', function () {
            clearTimeout(myTimer);
            var athis = $(this);
            myTimer = setTimeout(() => {
                if (fDebug) {
                    console.log('Reloading:' + athis.val());
                }
                $("#grid-orderlist").bootgrid('search', athis.val());
            }, 500);
        });

        $("#orderfiltertype").val(localStorage.getItem('cloud.' + cBrand + '.orderfiltertype'));
        $("#orderfiltertype").change(function () {
            localStorage.setItem('cloud.' + cBrand + '.orderfiltertype', $("#orderfiltertype").val());
            $(".editbutton").css('width', barWidth + '%');
            $("#grid-orderlist").bootgrid("reload");
        });
        $(".neworder").click(function () {
            checkPageChange('neworder', '', 'entry2');
        });
        grid.find(".command-edit").on("click", function () {
            checkPageChange('editor', $(this).data("row-id"), 'entry2');
        }).end().find(".command-address").on("click", function () {
            showAddressForm($(this).data("row-id"));
        }).end().find(".command-email").on("click", function () {
            var url = $(this).data('url');
            window.location.href = url;
        }).end().find(".command-delete").on("click", function () {
            var lData = $(this).data("row-id");
            bootbox.confirm("<h3>Cancel Order?</h3>", function (result) {
                if (result === true) {
                    $.ajax({
                        type: 'post',
                        dataType: 'html',
                        url: '/entry2/cancelorder.fsscript?rguid=' + lData,
                        data: {
                            "rguid": lData
                        },
                        success: function (r, status, xhr) {
                            if (status == 'success') {
                                showSuccess('Order Cancelled');
                                $("#grid-orderlist").bootgrid("reload");
                            } else {
                                showError(xhr.statusText);
                            }
                        },
                        error: function (e) {
                            showError(e.responseText);
                            console.error('Cancel Order Failure');
                            console.error(e);
                        }
                    });
                }
            });
        }).end().find(".command-print").on("click", function () {
            checkPageChange('printorder', $(this).data("row-id"), 'entry2');
        }).end().find(".command-3d").on("click", function () {
            if (p3D) {
                checkPageChange('preview3d', $(this).data("row-id"), 'entry2');
            }
        }).end().find(".command-image").on("click", function () {
            showImageForm($(this).data("row-id"));
        }).end().find(".command-retdate").on("click", function () {
            showRetDateForm($(this).data("row-id"));
        }).end().find(".command-clone").on("click", function () {
            if ($(this).data("disabled") === 'disabled') {
                bootbox.alert('<h3><i class="fa fa-exclamation-triangle" aria-hidden="true" style="font-size:36px;color:red"></i> Sorry cloning is no longer possible with legacy orders, please print the order and re-enter as a new order.</h3>');
            } else {
                cloneOrder($(this).data("row-id"), $(this).data("collection"), $(this).data("idretailer"), 'entry2');
            }
        });
        $('.dropdown-toggle').dropdown();
    });
    setTimeout(() => {
        $("#grid-orderlist").bootgrid('search', localStorage.getItem('cloud.' + cBrand + '.orderfilter'));
    }, 500);

};

function doFeedback(rguid, doReload) {
    //e.preventDefault();
    var dialogBox = bootbox.dialog({
        message: '<div id="feedback-content"></div>',
        title: '<i class="fal fa-megaphone fa-2x"></i> Order Feedback',
        buttons: {
            success: {
                label: "OK",
                className: "btn-outline-dark bntok",
                callback: function () {
                    $.ajax({
                        type: 'post',
                        url: '/scripts-pas/feedback.fsscript',
                        data: {
                            "update": "true",
                            "rguid": $('#rguid').val(),
                            "overall": $('#overall').val(),
                            "service": $('#service').val(),
                            "condition": $('#condition').val(),
                            "clothtrim": $('#clothtrim').val(),
                            "measurements": $('#measurements').val(),
                            "comments": encodeURI($('#comments').val())
                        },
                        success: function (r) {
                            //console.log(r);
                            var lObj = JSONstring.toObject(r);
                            if (lObj.Error != undefined) {
                                showError(lObj.Message);
                                console.error(lObj.Error);
                                $("#grid-orderlist").bootgrid("reload");
                            } else {
                                if (lObj.Message.indexOf('Error') < 0) {
                                    showSuccess(lObj.Message);
                                    $("#grid-orderlist").bootgrid("reload");
                                } else {
                                    showError(lObj.Message);
                                }
                            }
                            $('#account-content').html('');
                            if (doReload) {
                                document.location = '/';
                            }
                        },
                        error: function (request, status, error) {
                            showError('Error:' + request.responseText);
                            console.log(request);
                            console.log(status);
                            console.log(error);
                        }
                    });
                }
            },
            cancel: {
                label: "Cancel",
                className: "btn-default",
                callback: function () {
                    if (doReload) {
                        document.location = '/';
                    }
                }
            }
        }
    });
    dialogBox.init(function () {
        $('#feedback-content').loadForm('/scripts-pas/feedback.fsscript?edit=true&rguid=' + rguid,
            function (xhr, status) {
                if (status === 'success') {
                    $('.overallbutton').on('click', function () {
                        $('.overallbutton').removeClass('active');
                        $(this).addClass('active');
                        if ($(this).attr('id') === 'positive') {
                            $('#overall').val('3');
                        } else if ($(this).attr('id') === 'neutral') {
                            $('#overall').val('2');
                        } else if ($(this).attr('id') === 'negative') {
                            $('#overall').val('1');
                        }

                    });
                    $('.servicebutton').on('click', function () {
                        $('.servicebutton').removeClass('active');
                        $(this).addClass('active');
                        if ($(this).attr('id') === 'service_yes') {
                            $('#service').val(true);
                        } else {
                            $('#service').val(false);
                        }
                    });
                    $('.conditionbutton').on('click', function () {
                        $('.conditionbutton').removeClass('active');
                        $(this).addClass('active');
                        if ($(this).attr('id') === 'condition_yes') {
                            $('#condition').val(true);
                        } else {
                            $('#condition').val(false);
                        }
                    });
                    $('.clothtrimbutton').on('click', function () {
                        $('.clothtrimbutton').removeClass('active');
                        $(this).addClass('active');
                        if ($(this).attr('id') === 'clothtrim_yes') {
                            $('#clothtrim').val(true);
                        } else {
                            $('#clothtrim').val(false);
                        }
                    });
                    $('.measurementsbutton').on('click', function () {
                        $('.measurementsbutton').removeClass('active');
                        $(this).addClass('active');
                        if ($(this).attr('id') === 'measurements_yes') {
                            $('#measurements').val(true);
                        } else {
                            $('#measurements').val(false);
                        }
                    });
                    var checkValues = function () {

                    };

                    $(".vercontrol").keyup(function () {
                        checkValues(this);
                    });

                    $(".vercontrol").on('change', function () {
                        checkValues(this);
                    });
                }
            });
    });
    return false;
}

var showClothStocks = function () {

    fActiveGrid = $("#grid-clothstocks");

    var gridclothstock = $("#grid-clothstocks").bootgrid({
        ajax: true,
        post: function () {
            /* To accumulate custom parameter with the request object */
            return {
                id: "{671078d4-dc74-4933-9850-525ed510dcd4}"
            };
        },
        keepSelection: true,
        url: "/scripts-pas/clothstocklookup.fsscript",
        rowCount: [-1, 50, 100, 200],
        selection: false,
        multiSelect: false,
        columnSelection: false,
        statusMapping: {
            0: "",
            1: "warning",
            2: "danger",
            3: "error"
        },
        css: {
            searchField: "search-field-clothstocks form-control"
        },
        labels: {
            loading: "<i class='fa fa-cog fa-spin'></i> Loading...",
            all: "All",
            infos: "Showing {{ctx.start}} to {{ctx.end}} of {{ctx.total}} entries",
            noResults: "No results found!",
            refresh: "Refresh",
            search: "Search"
        },
        templates: {
            //<a href=\"/scripts-pas/clothstockxls.fsscript?pdf=true\" class=\"btn btn-success downloadpdf\"><i class=\"fa fa-file-spreadsheet\"></i> Download Full PDF</a>
            header: "<div id=\"{{ctx.id}}\" class=\"{{css.header}}\"><div class=\"row\"><div class=\"col-sm-12 actionBar\"><div class=\"form-group downloadclothstock\"><div class=\"input-group\"><a href=\"/scripts-pas/clothstockxls.fsscript\" class=\"btn btn-success downloadxls\"><i class=\"fa fa-file-spreadsheet\"></i> Download Full XLS</a></div></div><p class=\"{{css.search}}\"></p><p class=\"{{css.actions}}\"></p></div></div></div>"
        },
        converters: {
            datetime: {
                from: function (value) {
                    return new Date(value);
                },
                to: function (value) {
                    var dt = Date.parse(value);
                    var dto = new Date(dt);
                    if (dt < 0) {
                        return ('TBA');
                    } else {
                        return (dto.toLocaleDateString());
                    }
                }
            }
        },
        formatters: {
            balancedecode: function (column, row) {
                switch (parseInt(row.Balance)) {
                    case 0:
                        return translator.get('Discontinued');
                    case 1:
                        return translator.get('Out of Stock. ETA Up to 4 weeks');
                    case 2:
                        return translator.get('Out of Stock. ETA 5+ weeks');
                    case 3:
                        return translator.get('Contact Cloth Dept');
                    case 4:
                        return translator.get('Queried');
                    case 5:
                        if (row.LOCATION !== '') {
                            return translator.get('In Stock');
                        } else {
                            return translator.get('In Stock with Merchant');
                        }
                    default:
                        return translator.get('Unknown (' + row.Balance + ')');
                }
            },
            stocketacode: function (column, row) {
                if ((row.DT_STOCK_ETA !== undefined) &&
                    (parseFloat(row.DT_STOCK_ETA) > 0)) {
                    var dt = Date.parse(row.DT_STOCK_ETA);
                    var dto = new Date(dt);
                    if (dt < 0) {
                        return ('');
                    } else {
                        return (dto.toLocaleDateString());
                    }
                } else {
                    return ('');
                }
            },
            stockqtycode: function (column, row) {
                if (row.StockQty < 0 || row.StockQty === undefined) {
                    return 0.0;
                } else {
                    return row.StockQty.toFixed(2);
                }
            },
            locationcheckbox: function (column, row) {
                if (row.LOCATION !== '') {
                    return "Factory Stocked";
                } else {
                    return "Ordered from Merchant";
                }
                // if (row.LOCATION !== '') {
                //     return "<i class='fa fa-check-square-o'></i>";
                // } else {
                //     return "<i class='fa fa-square-o'></i>";
                // }
            }

        }
    }).on("loaded.rs.jquery.bootgrid", function () {
        localStorage.setItem('cloud.' + cBrand + '.clothstocksfilter', $('.search-field-clothstocks').val());
        var myTimer;
        $('.search-field-clothstocks').on('keydown keyup change copy paste cut', function () {
            var athis = $(this);
            clearTimeout(myTimer);
            myTimer = setTimeout(() => {
                $("#grid-clothstocks").bootgrid('search', athis.val());
            }, 500);
        });
        $('.dropdown-toggle').dropdown();
    });

    setTimeout(() => {
        gridclothstock.bootgrid('search', localStorage.getItem('cloud.' + cBrand + '.clothstocksfilter'));
    }, 500);
};

function doAWBUpdate() {
    var dialogBox = bootbox.dialog({
        message: '<div id="awb-content"></div>',
        title: '<i class="fal fa-mail-bulk fa-2x"></i> Update AWB Information',
        buttons: {
            success: {
                label: "OK",
                className: "btn-outline-dark bntok",
                callback: function () {
                    $.ajax({
                        type: 'post',
                        url: '/scripts-pas/awbupdate.fsscript',
                        data: {
                            "update": true,
                            "awbmemo": encodeURI($('#awbmemo').val())
                        },
                        success: function (r) {
                            //console.log(r);
                            var lObj = JSONstring.toObject(r);
                            if (lObj.Error != undefined) {
                                showError(lObj.Message);
                                console.error(lObj.Error);
                            } else {
                                if (lObj.Message.indexOf('Error') < 0) {
                                    showSuccess(lObj.Message);
                                    console.info(lObj.Message);
                                    $('grid-tracking').bootgrid("reload");
                                } else {
                                    showError(lObj.Message);
                                }
                            }
                        },
                        error: function (request, status, error) {
                            showError('Error:' + request.responseText);
                            console.log(request);
                            console.log(status);
                            console.log(error);
                        }
                    });
                }
            },
            cancel: {
                label: "Cancel",
                className: "btn-default",
                callback: function () {
                    // if (doReload) {
                    //     document.location = '/';
                    // }
                }
            }
        }
    });
    dialogBox.init(function () {
        $('#awb-content').loadForm('/scripts-pas/awbupdate.fsscript',
            function (xhr, status) {
                if (status === 'success') {
                    if (fDebug) {
                        console.log('Success');
                    }
                }
            });
    });
    return false;
}

$(document).ready(function () {

    $(".awbupdate").on('click', function () {
        doAWBUpdate();
    });

    LAM_ERROR_LOG.logger.enableLogger();

    $.fn.removeRange = function (start, end, increment, decimals = 2) {
        for (let index = start; index < end; index = index + increment) {
            if (Number.isInteger(increment)) { // Integer
                $(this).removeOption(index.toString());
            } else {
                $(this).removeOption((Math.round(index * 1000) / 1000).toFixed(decimals).toString());
            }
        }
    };

    $.fn.loadForm = function (url, callback) {
        var that = $(this);
        console.log('Load Page : ' + $(this).data('loading'));
        if (that.data('loading') !== 'true') {
            that.data('loading', 'true');
            console.log('Load Div');
            //var that = this; 
            //var re = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
            //var scripts = "";
            //var match;
            console.debug(that);
            console.debug(url);
            showLoadingPage();
            $.ajax({
                url: url,
                type: "get",
                success: function (data, status, xhr) {
                    //console.debug(data);
                    console.debug(status);
                    console.debug(xhr);
                    /*
                    while ((match = re.exec(data))) {
                        if (match[1] != "") {
                            // TODO: Any extra work here to eliminate existing scripts from being inserted
                            scripts += match[0];
                        }
                    }
                    */
                    try {
                        //that.html(scripts);
                        try {
                            //that.append(data);
                            that.html(data);
                            if (typeof callback === 'function') {
                                hideLoadingPage();
                                callback(xhr, status, data);
                            } else {
                                hideLoadingPage();
                            }
                            that.data('loading', 'false');
                        } catch (e) {
                            console.error(e);
                            //console.log(data);
                            hideLoadingPage();
                            that.data('loading', 'false');
                        }
                    } catch (e) {
                        console.error(e);
                        //console.log(scripts);
                        hideLoadingPage();
                        that.data('loading', 'false');
                    }
                },
                error: function (xhr, status, error) {
                    if (callback) {
                        callback(xhr, status, error);
                    }
                    hideLoadingPage();
                    that.data('loading', 'false');
                }
            });
        }
    };

    $.fn.switchPage = function (callback) {
        var that = $(this);
        var found = false;
        console.log('Switch Page : ' + that.data('switching'));
        if (that.data('switching') !== 'true') {
            that.data('switching', 'true');
            console.log(that);

            var runAfter = function () {
                console.log('Switch Page Run After : ' + that.data('switching'));
                that.data('switching', 'false');
                if (typeof callback === 'function') {
                    callback();
                }
            };

            pageArray.forEach(function (item) {
                if ($('#' + item.target).hasClass('show')) {
                    console.log($('#' + item.target));
                    console.log(that);
                    found = true;
                    if (typeof $('#' + item.target).velocity === 'function') {
                        try {
                            $('#' + item.target).velocity("transition.slideRightOut", 400, function () {
                                $('#' + item.target).addClass('hidden').removeClass('show');
                                that.addClass('show').removeClass('hidden');
                                that.velocity("transition.slideLeftIn", 400, runAfter);
                            });
                        } catch (error) {
                            that.addClass('show').removeClass('hidden');
                            runAfter();
                            console.error(error);
                        }
                    } else {
                        $('#' + item.target).addClass('hidden').removeClass('show');
                        that.addClass('show').removeClass('hidden');
                        runAfter();
                    }
                }
            });

            if (!found) {
                console.log('not found');
                that.addClass('show').removeClass('hidden');
                if (typeof that.velocity === 'function') {
                    try {
                        that.velocity("transition.slideLeftIn", 400, runAfter);
                    } catch (error) {
                        runAfter();
                        console.log(error);
                    }
                } else {
                    runAfter();
                }
            }
        }
    };

    $.fn.updateOrderID = function (pRGUID) {
        var fOrderTimer;
        var fCount = 0;

        function lookupOrderID(pRGUID) {
            $.ajax({
                type: 'post',
                async: false,
                dataType: 'json',
                url: '/scripts-pas/lookupOrderID.fsscript?rguid=' + pRGUID,
                cache: false,
                data: {
                    "rguid": pRGUID
                },
                success: function (r) {
                    fCount++;
                    if (fDebug) {
                        console.log(r);
                    }
                    if (r.error !== true && r.orderid.toLowerCase().indexOf('<unallocated>') < 0) {
                        $('#order_subject').html(r.subject);
                        clearInterval(fOrderTimer);
                    }
                    if (fCount > 10) {
                        clearInterval(fOrderTimer);
                    }
                },
                error: function (e) {
                    fCount++;
                    console.log('updateOrderID Failure');
                    console.log(e);
                }
            });
        }

        try {
            fOrderTimer = setInterval(function () {
                lookupOrderID(pRGUID);
            }, 5000);
        } catch (e) {
            console.error(e);
        }
    };

});

function showTrackingDetails(pRGUID) {
    $.ajax({
        type: 'post',
        url: '/scripts-pas/tracking-detail.fsscript',
        dataType: 'html',
        data: {
            "rguid": pRGUID
        },
        success: function (e) {
            console.log(e);
            var box = bootbox.dialog({
                message: e,
                title: 'Tracking Details',
                buttons: {
                    cancel: {
                        label: "Close",
                        className: "btn-default",
                        callback: function () {
                        }
                    }
                }
            });
            box.bind('shown.bs.modal', function () {
                //initRetDateForm();
            });
        },
        error: function (e) {
            console.log('updateField Failure');
            console.log(e);
        }
    });
}